import Head from "next/head";
import { Suspense } from "react";
import dynamic from "next/dynamic";
import { VingletLoader } from "@common";
import withHeader from "../app__Legacy/hocs/withHeader";
import withFooter from "../app__Legacy/hocs/withFooter";

const DynamicLogin = dynamic(() => import("@app-components").then((appComponents) => appComponents.Auth.Login), {
	suspense: true,
});

const LoginPage = () => {
	return (
		<>
			<Head>
				<title> Vinglet - Login </title>
			</Head>
			<Suspense fallback={<VingletLoader />}>
				<DynamicLogin />
			</Suspense>
		</>
	);
};

export default withHeader(withFooter(LoginPage));
